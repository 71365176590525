import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from '@/modules/auth/store'
import language from '@/modules/language/store'
import dropRateConfig from './drop_rate_config'
Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    language,
    dropRateConfig,
  },
  strict: process.env.DEV,
})
